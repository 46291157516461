import React, { useEffect, useRef, useState } from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEO';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
import { border, breakpoints, linesLimit, color } from '../../utils/style';
import { linksInterpolate, isNan } from '../../utils/system';
import CookieDisableComponent from '../../components/shared/CookieDisableError';

const WrapperDesktop = styled('div')`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 30px 0;
  height: max-content;

  @media (max-width: ${breakpoints.md}) {
    padding: 0;
  }
`;

const FeaturedWrapper = styled('div')`
  position: relative;
  div {
    border-radius: ${border.borderRadius};
  }

  div {
    @media (max-height: ${breakpoints.md}) and (orientation: portrait) {
      height: 140px;
    }
  }

  .breadcrumbs {
    position: absolute;
    top: 20px;
    left: 30px;
    z-index: 1;
    font-family: Kanit;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  h1 {
    position: absolute;
    top: 40%;
    left: 30px;
    z-index: 1;
    font-family: Kanit;
    font-size: 44px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;

    @media (max-width: ${breakpoints.md}) {
      font-family: Kanit;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2da5fe;
      top: 40%;
      left: 40px;
    }
  }

  @media (max-width: ${breakpoints.md}) {
    margin-bottom: 20px;

    .breadcrumbs {
      top: 20px;
      left: 16px;
      height: max-content !important;

      & div {
        height: max-content !important;
      }
    }

    & h1 {
      left: 16px;
    }
  }
`;

const Item = styled('div')`
  display: flex;
  flex-direction: column;
  width: calc(100% / 3);
  text-align: left;

  & img {
    border-radius: ${border.borderRadius};
  }

  @media (max-width: ${breakpoints.md}) and (orientation: portrait) {
    width: 100%;
    margin: 0;

    @media (min-height: ${breakpoints.md}) {
      width: 50%;
    }
  }

  @media (max-width: ${breakpoints.md}) and (orientation: landscape) {
    width: 50%;
    margin: 0;

    @media (max-width: ${breakpoints.sm}) {
      width: 100%;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    :nth-of-type(3n - 2) {
      padding: 30px 15px 0 0;
    }

    :nth-of-type(3n - 1) {
      padding: 30px 15px 0;
    }

    :nth-of-type(3n) {
      padding: 30px 0 0 15px;
    }
  }

  @media (max-width: ${breakpoints.md}) {
    & > a {
      & > div {
        margin-top: 10px;

        & > h3 {
          font-size: 18px;
          font-weight: 500;
          color: ${color.blue};
          margin-bottom: 10px;
        }

        & > div {
          font-size: 13px;
          line-height: 1.62;
          color: #f8f8f8;
          margin-bottom: 40px;
        }
      }
    }
  }

  div > h3 {
    font-size: 19px;
    margin: 0 0 5px 0;

    @media (max-height: ${breakpoints.md}) {
      font-size: 18px;
    }

    a {
      font-size: 19px;
      font-weight: 500;
      color: ${color.blue};
      @media (max-height: ${breakpoints.md}) {
        font-size: 18px;
        font-weight: 500;
        color: ${color.blue};
      }
    }
  }

  .gatsby-image-wrapper {
    min-height: 135px;
  }

  div > div {
    font-size: 14px;
    line-height: 1.57;
    color: #f8f8f8;
    max-height: 176px;
    ${linesLimit(8)};

    :nth-of-type(2) {
      text-overflow: ellipsis;
      overflow: hidden;
      max-height: 80px;
      font-family: Kanit;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.62;
      letter-spacing: normal;
      color: #f8f8f8;
    }
  }
`;

const PaginationWrapper = styled('div')`
  text-align: center;
  width: 100%;
  margin-bottom: 60px;
  a {
    border-radius: ${border.borderRadius};
    border: solid 1.4px #5c6a87;
    padding-top: 5px;
    text-align: center;
    cursor: pointer;
    margin-right: 16px;
    background-color: rgba(0, 0, 0, 0);
    width: 36px;
    height: 36px;
    display: inline-flex;
    text-align: center;
    color: white;
    :active,
    :hover {
      background-color: #28b7fa !important;
      border: none;
    }
  }
`;

const PaginationText = styled.span`
  text-align: center;
  width: 100%;
`;

const PageTitle = styled('h1')`
  padding: 0;
  margin: 0;
`;

const Description = styled('span')``;

// REFACTOR
// Spread / etc
const PaginatedPage = ({ data, pageContext }) => {
  const { group, pageCount, additionalContext } = pageContext;
  const checkIsCurrent = (edge, id) => edge.node.id === id;
  const pageNumber = pageContext.index;
  const currentCategoryArr = data.allWordpressCategory.edges.filter((edge) =>
    checkIsCurrent(edge, additionalContext.id));
  const currentCategory = currentCategoryArr[0].node;
  const path = currentCategory.path.replace('/category/', '');
  const metaTitle =
    currentCategory.yoast_meta && currentCategory.yoast_meta.yoast_wpseo_title
      ? `${currentCategory.yoast_meta.yoast_wpseo_title} - Page ${pageNumber} ${process.env.SITE_NAME_SHORT}`
      : `${currentCategory.title} - Page ${pageNumber}- ${process.env.SITE_NAME_SHORT}`;
  const metaDesc =
    currentCategory.yoast_meta && currentCategory.yoast_meta.yoast_wpseo_metadesc
      ? currentCategory.yoast_meta.yoast_wpseo_metadesc
      : '';
  const { breadcrumbs } = pageContext;

  const ref = useRef();

  const getPaginationElement = (page) => document.getElementById(`page-${page}`);
  useEffect(() => {
    if (window) {
      // The only way to update styles on pagination of button with number of pagination and nav menu
      const currPage = window.location.pathname.slice(0, -1);
      const currPageNum = +currPage.slice(currPage.lastIndexOf('/') + 1);

      const currSlug = currPage.slice(0, -2);
      const currNavEl = document.getElementById(currSlug.slice(1));

      const prevPage = getPaginationElement(ref.current);
      const nextPage = getPaginationElement(currPageNum);
      if (isNan(currPageNum)) {
        ref.current = 1;
        if (getPaginationElement(1)) {
          getPaginationElement(1).style.backgroundColor = '#28b7fa';
        }
      }
      if (prevPage) {
        prevPage.style.backgroundColor = '';
      }
      if (nextPage) {
        nextPage.style.backgroundColor = '#28b7fa';
      }
      if (currNavEl) {
        currNavEl.style.borderBottom = '3px solid red';
      }
      if (currNavEl) {
        currNavEl.childNodes[currNavEl.childNodes.length - 1].style.color = 'red';
      }
      if (isNan(currPageNum)) {
        ref.current = 1;
      }
      ref.current = currPageNum;
    }
  }, []);

  const Pagination = ({ count }) => {
    const arr = new Array(count);
    arr.fill(null);
    return (
      <PaginationWrapper>
        {arr?.map((el, i) => {
          const realPage = i + 1;

          if (realPage === 1) {
            return (
              <Link
                aria-label={`page ${realPage}`}
                className="pagination"
                to={linksInterpolate`${path}`}
                id={`page-${realPage}`}
              >
                <PaginationText>{realPage}</PaginationText>
              </Link>
            );
          }

          return (
            <Link
              aria-label={`page ${realPage}`}
              className="pagination"
              to={linksInterpolate`${path}/${realPage}`}
              id={`page-${realPage}`}
            >
              <PaginationText>{realPage}</PaginationText>
            </Link>
          );
        })}
      </PaginationWrapper>
    );
  };

  const [localError, setLocalError] = useState(false);

  useEffect(() => {
    try {
      if (window.localStorage) {
        setLocalError(false);
      }
    } catch (error) {
      setLocalError(true);
    }
  });

  if (!localError) {
    return (
      <Layout>
        <SEO title={metaTitle} description={metaDesc} pathname={currentCategory.path} breadcrumbs={breadcrumbs} />
        {currentCategory.acf && currentCategory.acf.category_image && (
          <FeaturedWrapper>
            <Img
              fluid={currentCategory.acf.category_image.localFile.childImageSharp.fluid}
              alt={
                currentCategory.acf.category_image.alt_text
                  ? currentCategory.acf.category_image.alt_text
                  : currentCategory.name
              }
              fadeIn={false}
              loading="eager"
            />
            <Breadcrumbs breadcrumbs={breadcrumbs} page_title={currentCategory.name} />
            <PageTitle>{currentCategory.name}</PageTitle>
          </FeaturedWrapper>
        )}
        <WrapperDesktop>
          {group?.map(({ node }) => (
            <Item key={node.slug}>
              <Link to={linksInterpolate`${node.slug}`}>
                {node.featured_media &&
                node.featured_media.localFile &&
                node.featured_media.localFile.childImageSharp.fluid && (
                  <Img
                    fluid={node.featured_media.localFile.childImageSharp.fluid}
                    alt={node.featured_media.alt_text ? node.featured_media.alt_text : node.title}
                    fadeIn={false}
                    loading="eager"
                  />
                )}
                <div>
                  <h3>
                    <Link to={linksInterpolate`${node.slug}`}>{node.title}</Link>
                  </h3>
                  <div dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                </div>
              </Link>
            </Item>
          ))}
        </WrapperDesktop>
        {pageCount > 1 && <Pagination count={pageCount} />}

        {currentCategory.excerpt && <Description>{currentCategory.excerpt}</Description>}
      </Layout>
    );
  }

  return (<CookieDisableComponent />);
};

export default PaginatedPage;

export const pageQuery = graphql`
  query($id: String) {
    allWordpressCategory(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          name
          slug
          path
          link
          acf {
            category_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
          yoast_meta {
            yoast_wpseo_title
            yoast_wpseo_metadesc
          }
        }
      }
    }
    placeholder: file(relativePath: { eq: "placeholder.png" }) {
      childImageSharp {
        fixed(width: 190, height: 190) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`;
